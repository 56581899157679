













import Vue, { PropType } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import DefaultNavItemContent from '~/components/car/dealers/site/nav/NavItemContent/DefaultNavItemContent.vue'
import SearchPageNavItemContent from '~/components/car/dealers/site/nav/NavItemContent/SearchPageNavItemContent.vue'
import { PageSnippet } from '~/models/dealer/site/page-snippet/types'
import { DEALER_SITE_NS } from '~/store/modules/shared/dealers/site/state'
import DealerSiteService from '~/services/dealers/site/DealerSiteService'
import { mapDeps } from '~/plugins/dependency-container/utils'
import DealerSiteComponentService from '~/services/dealers/site/DealerSiteComponentService'

export default Vue.extend({
  components: {
    DefaultNavItemContent,
    SearchPageNavItemContent
  },
  props: {
    page: {
      type: Object as PropType<PageSnippet>,
      required: true
    }
  },
  computed: {
    ...mapDeps({
      dealerSiteService: DealerSiteService,
      dealerSiteComponentService: DealerSiteComponentService
    }),
    ...mapGetters(DEALER_SITE_NS, ['pageIsActive']),
    active(): boolean {
      const { page, pageIsActive } = this

      // Patch: Since update is not queued and active state is not rendered as it should.
      this.$forceUpdate()

      return pageIsActive(page.id)
    }
  },
  methods: {
    ...mapActions(DEALER_SITE_NS, ['navigateToPageFromOffCanvasNav']),
    getPageHref(page: any) {
      return this.dealerSiteService.resolvePageRoute(page).href
    },
    getPageComponent(page: any) {
      return this.dealerSiteComponentService.getNavItemComponentName(page.type)
    }
  }
})
