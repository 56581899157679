





import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { DEALER_SITE_NS } from '~/store/modules/shared/dealers/site/state'
import { mapDeps } from '~/plugins/dependency-container/utils'
import DealerSiteService from '~/services/dealers/site/DealerSiteService'

export default Vue.extend({
  computed: {
    ...mapGetters(DEALER_SITE_NS, ['contactPage', 'pageIsActive']),
    ...mapDeps({ dealerSiteService: DealerSiteService }),
    icons: (): Record<string, IconDefinition> => ({
      arrowRight: faArrowCircleRight
    }),
    shown(): boolean {
      return (
        (this.contactPage && !this.pageIsActive(this.contactPage.id)) ||
        !this.contactPage
      )
    }
  }
})
