






import Vue, { PropType } from 'vue'
import { PageSnippet } from '~/models/dealer/site/page-snippet/types'

export default Vue.extend({
  props: {
    page: {
      type: Object as PropType<PageSnippet>,
      required: true
    }
  }
})
