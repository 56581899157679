






































































import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import ContactPageLink from '~/components/car/dealers/site/footer/ContactPageLink.vue'
import { SocialMediaLinks } from '~/models/dealer/types'
import { convertHexToRgb, colorIsDark } from '~/utils/color'
import { DEALER_SITE_NS } from '~/store/modules/shared/dealers/site/state'
import LanguagePicker from '../LanguagePicker.vue'
import ContactSnippet from './ContactSnippet.vue'
import OutletSocialIconGroup from '../outlet/OutletSocialIconGroup.vue'
import Spinner from '../Spinner.vue'
import LogoService from '~/services/LogoService'

export default Vue.extend({
  components: {
    LanguagePicker,
    ContactSnippet,
    OutletSocialIconGroup,
    ContactPageLink,
    Spinner
  },
  props: {
    availableLocales: {
      type: Array,
      required: true
    }
  },
  data(): { logo: string | undefined } {
    return {
      logo: undefined
    }
  },
  computed: {
    ...mapState(DEALER_SITE_NS, ['outlets']),
    ...mapGetters(DEALER_SITE_NS, ['getTemplateOption']),
    textColor(): string {
      const { getTemplateOption } = this
      return getTemplateOption('footer_text_color')
    },
    textColorRgb(): string {
      return convertHexToRgb(this.getTemplateOption('footer_text_color')).join(
        ', '
      )
    },
    firstOutletSocialMediaLinks(): SocialMediaLinks {
      const { outlets } = this
      if (!outlets.size) {
        return {}
      }

      return [...outlets.values()][0].socialMediaLinks
    },
    firstOutletHasSocialMediaLinks(): boolean {
      const { firstOutletSocialMediaLinks } = this
      return Boolean(Object.values(firstOutletSocialMediaLinks).some(l => l))
    },
    renderSocialMediaColumn(): boolean {
      return this.firstOutletHasSocialMediaLinks // TODO: read customizer setting when implemented
    },
    renderContactColumn(): boolean {
      return true // TODO: read customizer setting when implemented
    },
    backgroundIsDark(): boolean {
      return colorIsDark(this.getTemplateOption('footer_background_color'))
    },
    colWidth(): number {
      return this.renderSocialMediaColumn ? 3 : 4
    },
    smallColWidth(): number {
      return 12
    }
  },
  async mounted() {
    const logoService = this.$dep(LogoService)
    this.logo = await logoService.getLogo(
      this.getTemplateOption('footer_background_color'),
      true
    )
  }
})
